import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { subDays, parseISO, isAfter, isBefore, format } from 'date-fns';

const FallingProducts = ({ orders }) => {
  const [days, setDays] = useState(7); // Pode ser 7 ou 30

  const today = new Date();
  const startCurrent = subDays(today, days);
  const startPrevious = subDays(startCurrent, days);

  // Separa os pedidos por período
  const { currentPeriodStats, previousPeriodStats } = useMemo(() => {
    const current = {};
    const previous = {};

    orders.forEach(order => {
      const date = parseISO(order.purchased_at);
      const period = isAfter(date, startCurrent) ? 'current' : isAfter(date, startPrevious) && isBefore(date, startCurrent) ? 'previous' : null;

      if (!period) return;

      order.deliveries?.forEach(delivery => {
        delivery.items?.forEach(item => {
          const sku = item.info.sku;
          const target = period === 'current' ? current : previous;

          if (!target[sku]) {
            target[sku] = {
              name: item.info.name,
              image: item.info.images?.[0]?.url || '',
              quantity: 0,
            };
          }

          target[sku].quantity += item.quantity;
        });
      });
    });

    return {
      currentPeriodStats: current,
      previousPeriodStats: previous,
    };
  }, [orders, days]);

  // Calcula a queda por produto
  const fallingProducts = useMemo(() => {
    const result = [];

    Object.entries(previousPeriodStats).forEach(([sku, prevData]) => {
      const currentData = currentPeriodStats[sku] || { quantity: 0 };
      if (prevData.quantity > currentData.quantity) {
        const drop = prevData.quantity - currentData.quantity;
        const percentage = ((drop / prevData.quantity) * 100).toFixed(1);
        result.push({
          sku,
          name: prevData.name,
          image: prevData.image,
          previous: prevData.quantity,
          current: currentData.quantity,
          drop,
          percentage,
        });
      }
    });

    return result.sort((a, b) => b.drop - a.drop);
  }, [previousPeriodStats, currentPeriodStats]);

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-lg text-white">
      <h2 className="text-2xl font-bold mb-4 text-red-400 text-center">📉 Produtos com Queda de Vendas</h2>

      {/* Filtro de período */}
      <div className="flex justify-center gap-4 mb-6">
        <button
          onClick={() => setDays(7)}
          className={`px-4 py-2 rounded ${days === 7 ? 'bg-red-600' : 'bg-gray-700'} hover:bg-red-700`}
        >
          Últimos 7 dias
        </button>
        <button
          onClick={() => setDays(30)}
          className={`px-4 py-2 rounded ${days === 30 ? 'bg-red-600' : 'bg-gray-700'} hover:bg-red-700`}
        >
          Últimos 30 dias
        </button>
      </div>

      {fallingProducts.length === 0 ? (
        <p className="text-center text-gray-400">Nenhuma queda significativa detectada no período selecionado.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {fallingProducts.map((product, idx) => (
            <div key={idx} className="flex items-center bg-gray-800 p-4 rounded shadow-md">
              <img src={product.image} alt={product.name} className="w-16 h-16 rounded object-cover mr-4" />
              <div>
                <h3 className="font-semibold text-lg text-red-300">{product.name}</h3>
                <p>Anterior: {product.previous} | Atual: {product.current}</p>
                <p className="text-red-400 font-semibold">🔻 {product.percentage}% de queda</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

FallingProducts.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default FallingProducts;
