import React, { useState, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import {
  FaCreditCard,
  FaMoneyBillWave,
  FaPaypal,
  FaQuestionCircle,
} from "react-icons/fa";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

/* 
  Componente Modal Base com título e atributos de acessibilidade.
*/
const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;
  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
      aria-modal="true"
      role="dialog"
    >
      <div className="bg-gray-900 p-6 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/3 relative">
        <div className="flex justify-between items-center mb-4">
          {title && (
            <h2 className="text-2xl font-bold text-white">{title}</h2>
          )}
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-white"
            aria-label="Fechar modal"
          >
            ✖
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

/*
  Componente CurvaABC
  - Agrega os dados de vendas por produto.
  - Permite filtrar por nome do produto.
  - Exibe detalhes e gráfico de vendas agrupado por dia/mês.
*/
const CurvaABC = ({ orders }) => {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [groupBy, setGroupBy] = useState("day");
  const [searchQuery, setSearchQuery] = useState("");

  const handleDownloadReport = () => {
    const monthlyHeaders = new Set();
    const productRows = [];

    sortedProducts.forEach((product) => {
      const salesByMonth = {};
      product.sales.forEach(({ date, quantity }) => {
        const month = date.slice(0, 7);
        monthlyHeaders.add(month);
        salesByMonth[month] = (salesByMonth[month] || 0) + quantity;
      });

      const row = {
        SKU: product.sku, // <- Isso agora funcionará
        Nome: product.name,
        Imagem: product.image,
        "Total Vendido": product.quantity,
        "Faturamento Total (R$)": product.revenue.toFixed(2),
        Classe: product.category,
      };

      [...monthlyHeaders].forEach((month) => {
        row[`Vendas ${month}`] = salesByMonth[month] || 0;
      });

      productRows.push(row);
    });

    const sortedByQuantity = [...sortedProducts].sort((a, b) => b.quantity - a.quantity);
    const maisVendido = sortedByQuantity[0]?.name || "";
    const menosVendido = sortedByQuantity.at(-1)?.name || "";

    // Planilha principal
    const ws = XLSX.utils.json_to_sheet(productRows);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Curva ABC");

    // Segunda aba com resumo
    const resumo = [
      { Métrica: "Produto Mais Vendido", Valor: maisVendido },
      { Métrica: "Produto Menos Vendido", Valor: menosVendido },
    ];

    const allMonths = [...monthlyHeaders].sort();
    allMonths.forEach((month, i) => {
      if (i > 0) {
        const currentMonth = month;
        const prevMonth = allMonths[i - 1];
        const diff = sortedProducts.reduce((acc, p) => {
          const curr = p.sales
            .filter((s) => s.date.slice(0, 7) === currentMonth)
            .reduce((sum, s) => sum + s.quantity, 0);
          const prev = p.sales
            .filter((s) => s.date.slice(0, 7) === prevMonth)
            .reduce((sum, s) => sum + s.quantity, 0);
          return acc + (curr - prev);
        }, 0);

        resumo.push({
          Métrica: `Diferença ${prevMonth} → ${currentMonth}`,
          Valor: diff > 0 ? `+${diff}` : `${diff}`,
        });
      }
    });

    const wsResumo = XLSX.utils.json_to_sheet(resumo);
    XLSX.utils.book_append_sheet(wb, wsResumo, "Resumo");

    // Download
    const buffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([buffer], { type: "application/octet-stream" });
    saveAs(blob, "relatorio_curva_abc.xlsx");
  };


  // Agrega os dados de vendas para cada produto
  const productSales = useMemo(() => {
    const sales = {};
    orders.forEach((order) => {
      order.deliveries?.forEach((delivery) => {
        delivery.items?.forEach((item) => {
          const sku = item.info.sku;
          if (!sales[sku]) {
            sales[sku] = {
              sku: sku, // <- Adicionando o SKU corretamente
              name: item.info.name,
              revenue: 0,
              image: item.info.images[0]?.url || "",
              quantity: 0,
              sales: [],
            };
          }
          sales[sku].revenue += (item.unit_price.value / 100) * item.quantity;
          sales[sku].quantity += item.quantity;
          sales[sku].sales.push({
            date: new Date(order.created_at).toISOString().split("T")[0],
            quantity: item.quantity,
          });
        });
      });
    });
    return sales;
  }, [orders]);

  // Ordena os produtos e calcula as classes A, B e C
  const sortedProducts = useMemo(() => {
    const products = Object.values(productSales);
    const totalRevenue = products.reduce((sum, p) => sum + p.revenue, 0);
    let accumulated = 0;
    return products
      .sort((a, b) => b.revenue - a.revenue)
      .map((product) => {
        accumulated += product.revenue;
        let category = "C";
        if (accumulated / totalRevenue <= 0.8) category = "A";
        else if (accumulated / totalRevenue <= 0.95) category = "B";
        return {
          ...product,
          category,
          percentage: ((product.revenue / totalRevenue) * 100).toFixed(2),
        };
      });
  }, [productSales]);

  // Filtro de busca por nome do produto
  const filteredProducts = useMemo(() => {
    if (!searchQuery.trim()) return sortedProducts;
    return sortedProducts.filter((product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [sortedProducts, searchQuery]);

  // Função para agrupar os dados de vendas por dia ou mês
  const groupSalesData = useCallback(
    (sales) => {
      const grouped = {};
      sales.forEach(({ date, quantity }) => {
        const key = groupBy === "month" ? date.slice(0, 7) : date;
        grouped[key] = (grouped[key] || 0) + quantity;
      });
      return Object.entries(grouped).map(([date, quantity]) => ({
        date,
        quantity,
      }));
    },
    [groupBy]
  );

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-lg text-white flex flex-col min-h-[400px] max-h-[500px] overflow-y-auto">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-purple-400">
          📊 Curva ABC
        </h2>
        <button
          onClick={handleDownloadReport}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 ml-4"
        >
          📥 Baixar Relatório
        </button>
        <input
          type="text"
          placeholder="Buscar produto..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="p-2 rounded bg-gray-800 text-white placeholder-gray-400"
        />

      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 flex-grow">
        {filteredProducts.map((product, index) => (
          <div
            key={index}
            className={`flex items-center p-4 rounded-lg shadow-lg hover:scale-105 transition-transform cursor-pointer ${product.category === "A"
              ? "bg-green-800"
              : product.category === "B"
                ? "bg-yellow-700"
                : "bg-red-800"
              }`}
            onClick={() => {
              setSelectedProduct(product);
              setModalOpen(true);
            }}
          >
            <img
              src={product.image}
              alt={product.name}
              className="w-16 h-16 rounded-lg mr-4 shadow-lg"
            />
            <div>
              <h3 className="text-md font-semibold">{product.name}</h3>
              <p className="text-gray-300">Quantidade: {product.quantity}</p>
              <p className="text-green-300">
                Faturamento: R$ {product.revenue.toFixed(2)}
              </p>
              <p className="text-sm font-bold text-white">
                Classe {product.category}
              </p>
              <p className="text-sm text-gray-400">
                📈 {product.percentage}% do total
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Modal de detalhes do produto */}
      {modalOpen && selectedProduct && (
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          title={selectedProduct.name}
        >
          <div className="p-4 bg-gray-800 rounded-lg">
            <p className="text-gray-300">
              Total vendido: {selectedProduct.quantity} unidades
            </p>
            <div className="mt-4 flex space-x-2">
              <button
                onClick={() => setGroupBy("day")}
                className={`px-4 py-2 rounded ${groupBy === "day" ? "bg-blue-500" : "bg-gray-600"
                  } hover:bg-blue-700`}
              >
                Agrupar por Dia
              </button>
              <button
                onClick={() => setGroupBy("month")}
                className={`px-4 py-2 rounded ${groupBy === "month" ? "bg-blue-500" : "bg-gray-600"
                  } hover:bg-blue-700`}
              >
                Agrupar por Mês
              </button>
            </div>
            <div className="mt-4 w-full h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={groupSalesData(selectedProduct.sales)}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="quantity" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <button
              className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-800"
              onClick={() => setModalOpen(false)}
            >
              Fechar
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

CurvaABC.propTypes = {
  orders: PropTypes.array.isRequired,
};

/*
  Componente PaymentMethods
  - Agrega os dados dos métodos de pagamento e exibe estatísticas.
  - Possui filtro por método (dropdown) e opção de agrupar por dia/mês.
  - Exibe gráficos de evolução e detalhes em modal.
*/
const PaymentMethods = ({ orders }) => {
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [groupBy, setGroupBy] = useState("day");
  const [filterMethod, setFilterMethod] = useState("all");

  // Agrega os dados dos métodos de pagamento
  const { methods, totalRevenue } = useMemo(() => {
    const acc = {};
    let totalRev = 0;
    orders.forEach((order) => {
      const method = order.payments?.[0]?.method || "desconhecido";
      if (!acc[method]) {
        acc[method] = { count: 0, revenue: 0, transactions: [] };
      }
      const value = order.amounts.total / 100;
      acc[method].count += 1;
      acc[method].revenue += value;
      totalRev += value;
      acc[method].transactions.push({
        date: new Date(order.created_at).toISOString().split("T")[0],
        value,
      });
    });
    return { methods: acc, totalRevenue: totalRev };
  }, [orders]);

  // Filtro de métodos
  const filteredMethods = useMemo(() => {
    if (filterMethod === "all") return Object.entries(methods);
    return Object.entries(methods).filter(
      ([method]) => method === filterMethod
    );
  }, [methods, filterMethod]);

  // Agrupa transações por dia/mês para o modal
  const groupTransactions = useCallback(
    (transactions) => {
      const grouped = {};
      const dailyTotals = {};
      transactions.forEach(({ date, value }) => {
        const key = groupBy === "month" ? date.slice(0, 7) : date;
        grouped[key] = grouped[key] || { total: 0 };
        dailyTotals[key] = (dailyTotals[key] || 0) + value;
        grouped[key].total += value;
      });
      return Object.entries(grouped).map(([date, data]) => ({
        date,
        value: data.total,
        percentage: dailyTotals[date]
          ? ((data.total / dailyTotals[date]) * 100).toFixed(2)
          : "0",
      }));
    },
    [groupBy]
  );

  // Agrupa todos os métodos de pagamento para o gráfico geral
  const groupAllPayments = useMemo(() => {
    const groupedPayments = {};
    orders.forEach((order) => {
      const method = order.payments?.[0]?.method || "desconhecido";
      const value = order.amounts.total / 100;
      const date = new Date(order.created_at)
        .toISOString()
        .split("T")[0];
      const key = groupBy === "month" ? date.slice(0, 7) : date;
      groupedPayments[key] = groupedPayments[key] || {};
      groupedPayments[key][method] =
        (groupedPayments[key][method] || 0) + value;
    });
    return Object.entries(groupedPayments).map(([date, methods]) => ({
      date,
      ...methods,
    }));
  }, [orders, groupBy]);

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-lg mt-6 text-white flex flex-col">
      <h2 className="text-xl font-semibold text-purple-400 mb-4">
        💳 Métodos de Pagamento
      </h2>
      <div className="flex justify-between items-center mb-4">
        <select
          value={filterMethod}
          onChange={(e) => setFilterMethod(e.target.value)}
          className="p-2 bg-gray-800 rounded text-white"
        >
          <option value="all">Todos</option>
          {Object.keys(methods).map((method, idx) => (
            <option key={idx} value={method}>
              {method.replace("_", " ")}
            </option>
          ))}
        </select>
        <div>
          <button
            onClick={() => setGroupBy("day")}
            className={`px-4 py-2 mr-2 rounded ${groupBy === "day" ? "bg-blue-500" : "bg-gray-600"
              } hover:bg-blue-700`}
          >
            Agrupar por Dia
          </button>
          <button
            onClick={() => setGroupBy("month")}
            className={`px-4 py-2 rounded ${groupBy === "month" ? "bg-blue-500" : "bg-gray-600"
              } hover:bg-blue-700`}
          >
            Agrupar por Mês
          </button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredMethods.map(([method, data], index) => {
          const percentage = ((data.revenue / totalRevenue) * 100).toFixed(2);
          return (
            <div
              key={index}
              className="flex flex-col items-center p-4 bg-gray-800 rounded-lg shadow-lg hover:scale-105 transition-transform cursor-pointer"
              onClick={() => {
                setSelectedMethod({ method, ...data });
                setModalOpen(true);
              }}
            >
              <h3 className="text-lg font-semibold capitalize mt-2">
                {method.replace("_", " ")}
              </h3>
              <p className="text-xl font-bold text-green-400">
                R$ {data.revenue.toFixed(2)}
              </p>
              <p className="text-md text-gray-400">
                {data.count} transações ({percentage}%)
              </p>
            </div>
          );
        })}
      </div>
      <div className="mt-6 w-full h-96 bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-xl font-semibold text-purple-400 mb-4">
          📊 Evolução dos Métodos de Pagamento
        </h2>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={groupAllPayments}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip
              content={({ payload }) => {
                if (!payload || payload.length === 0) return null;
                const dailyTotal = payload.reduce(
                  (sum, entry) => sum + (entry.value || 0),
                  0
                );
                return (
                  <div className="bg-gray-800 p-2 rounded">
                    <p className="text-white font-bold">
                      {payload[0].payload.date}
                    </p>
                    {payload.map((entry, index) => {
                      const value =
                        typeof entry.value === "number"
                          ? entry.value.toFixed(2)
                          : "N/A";
                      const percentage = dailyTotal
                        ? ((entry.value / dailyTotal) * 100).toFixed(2)
                        : "0";
                      return (
                        <p key={index} className="text-white">
                          {entry.name}: R$ {value} ({percentage}%)
                        </p>
                      );
                    })}
                  </div>
                );
              }}
            />
            <Legend />
            {Object.keys(
              orders.reduce((acc, order) => {
                const method = order.payments?.[0]?.method || "desconhecido";
                acc[method] = true;
                return acc;
              }, {})
            ).map((method, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={method}
                stroke={`hsl(${index * 50}, 70%, 50%)`}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </div>

      {/* Modal com gráfico detalhado do método selecionado */}
      {modalOpen && selectedMethod && (
        <Modal
          isOpen={modalOpen}
          onClose={() => setModalOpen(false)}
          title={`Detalhes - ${selectedMethod.method.replace("_", " ")}`}
        >
          <div className="p-6 bg-gray-800 rounded-lg">
            <p className="text-gray-300">
              Total: R$ {selectedMethod.revenue.toFixed(2)}
            </p>
            <p className="text-gray-300">
              Transações: {selectedMethod.count}
            </p>
            <div className="mt-4 w-full h-64">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={groupTransactions(selectedMethod.transactions)}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis />
                  <Tooltip contentStyle={{ backgroundColor: "#333", color: "#fff" }} />
                  <Legend />
                  <Line type="monotone" dataKey="value" stroke="#82ca9d" />
                </LineChart>
              </ResponsiveContainer>
            </div>
            <button
              className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-800"
              onClick={() => setModalOpen(false)}
            >
              Fechar
            </button>
          </div>
        </Modal>
      )}
    </div>
  );
};

PaymentMethods.propTypes = {
  orders: PropTypes.array.isRequired,
};

export { CurvaABC, PaymentMethods };
