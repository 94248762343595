import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Area,
  Legend,
} from 'recharts';
import { format, parseISO, isAfter, subDays } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { motion, AnimatePresence } from 'framer-motion';

/* ===== Helper Functions ===== */

// Agrupa os dados dos pedidos por dia
const groupByDay = (orders) => {
  const grouped = orders.reduce((acc, order) => {
    const dateKey = format(parseISO(order.purchased_at), 'yyyy-MM-dd');
    const totalAmount = order.amounts?.total ? order.amounts.total / 100 : 0;
    if (!acc[dateKey]) {
      acc[dateKey] = { date: dateKey, total: 0, orders: 0 };
    }
    acc[dateKey].total += totalAmount;
    acc[dateKey].orders += 1;
    return acc;
  }, {});
  return Object.values(grouped).sort((a, b) => new Date(a.date) - new Date(b.date));
};

// Calcula KPIs para cada dia: total acumulado, média móvel de 7 dias e média por pedido
const calculateKPIs = (data) => {
  let cumulativeTotal = 0;
  const windowSize = 7;
  return data.map((item, index) => {
    cumulativeTotal += item.total;
    const windowStart = Math.max(0, index - windowSize + 1);
    const windowData = data.slice(windowStart, index + 1);
    const movingAverage = windowData.reduce((sum, cur) => sum + cur.total, 0) / windowData.length;
    const averageSale = item.orders ? item.total / item.orders : 0;
    return {
      ...item,
      cumulativeTotal,
      movingAverage,
      averageSale,
    };
  });
};

// Agrupa os pedidos por SKU
const groupOrdersBySKU = (orders) => {
  return orders.reduce((acc, order) => {
    const sku = order.sku || 'N/A';
    if (!acc[sku]) {
      acc[sku] = [];
    }
    acc[sku].push(order);
    return acc;
  }, {});
};

/* ===== Componentes de Modal ===== */

// Modal genérico utilizando framer-motion e Tailwind CSS
const Modal = ({ isOpen, onClose, title, children }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
          aria-modal="true"
          role="dialog"
        >
          <motion.div
            initial={{ scale: 0.8, y: 50 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.8, y: 50 }}
            className="bg-gray-800 p-6 rounded-lg shadow-lg w-11/12 md:w-1/2 lg:w-1/3 max-h-[80vh] overflow-y-auto"
          >
            <div className="flex justify-between items-center mb-4">
              {title && (
                <h2 className="text-2xl font-bold text-white">{title}</h2>
              )}
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-white"
                aria-label="Fechar modal"
              >
                ✖
              </button>
            </div>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

// Modal para exibir a lista de pedidos agrupados por SKU
const OrderModal = ({ isOpen, onClose, groupedOrders, selectedDate }) => {
  if (!isOpen) return null;
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
        >
          <motion.div
            initial={{ scale: 0.8, y: 50 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.8, y: 50 }}
            className="bg-gray-800 p-6 rounded-lg max-h-[80vh] overflow-y-auto w-11/12 md:w-1/2 lg:w-1/3"
          >
            <h3 className="text-white text-xl font-bold mb-4">
              Pedidos do dia {format(parseISO(selectedDate), 'dd/MM/yyyy')}
            </h3>
            {Object.keys(groupedOrders).length === 0 ? (
              <p className="text-white">Nenhum pedido encontrado.</p>
            ) : (
              Object.keys(groupedOrders).map((sku) => (
                <div key={sku} className="mb-4">
                  <h4 className="text-purple-500 font-semibold">SKU: {sku}</h4>
                  <ul className="list-disc pl-5">
                    {groupedOrders[sku].map((order) => (
                      <li key={order.id} className="text-white">
                        Código: {order.code} - Total: R$ {(
                          order.amounts?.total
                            ? order.amounts.total / 100
                            : 0
                        ).toFixed(2)}
                      </li>
                    ))}
                  </ul>
                </div>
              ))
            )}
            <div className="mt-4 flex justify-end">
              <button
                onClick={onClose}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
              >
                Fechar
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

OrderModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  groupedOrders: PropTypes.object.isRequired,
  selectedDate: PropTypes.string.isRequired,
};

/* ===== Componentes de Gráfico ===== */

// Custom Dot para interação no gráfico (abre modal com detalhes)
const CustomDot = ({ cx, cy, payload, onDotClick }) => {
  return (
    <circle
      cx={cx}
      cy={cy}
      r={5}
      fill="#ff00ff"
      stroke="#fff"
      onClick={() => onDotClick(payload)}
      style={{ cursor: 'pointer' }}
    />
  );
};

CustomDot.propTypes = {
  cx: PropTypes.number.isRequired,
  cy: PropTypes.number.isRequired,
  payload: PropTypes.object.isRequired,
  onDotClick: PropTypes.func.isRequired,
};

// Tooltip customizado com KPIs adicionais
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const parsedDate = parseISO(label);
    const formattedDate = format(parsedDate, 'dd/MM/yyyy');
    const dayOfWeek = format(parsedDate, 'eeee', { locale: ptBR });
    const { total, cumulativeTotal, movingAverage, orders, averageSale } = payload[0].payload;
    return (
      <div className="bg-gray-900 border border-purple-500 p-2 rounded">
        <p className="text-white"><strong>Data:</strong> {formattedDate}</p>
        <p className="text-white"><strong>Dia da semana:</strong> {dayOfWeek}</p>
        <p className="text-white"><strong>Total do Dia:</strong> R$ {total.toFixed(2)}</p>
        <p className="text-white"><strong>Pedidos:</strong> {orders}</p>
        <p className="text-white"><strong>Média por Pedido:</strong> R$ {averageSale.toFixed(2)}</p>
        <p className="text-white"><strong>Total Acumulado:</strong> R$ {cumulativeTotal.toFixed(2)}</p>
        <p className="text-white"><strong>Média Móvel (7 dias):</strong> R$ {movingAverage.toFixed(2)}</p>
      </div>
    );
  }
  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.string,
};

/* ===== Componente Principal: SalesChart ===== */

const SalesChart = ({ orders }) => {
  // Estados para filtros e toggles
  const [timeFilter, setTimeFilter] = useState('all'); // '7days', '30days', 'all'
  const [showCumulative, setShowCumulative] = useState(false);
  const [showMovingAverage, setShowMovingAverage] = useState(true);
  const [showArea, setShowArea] = useState(true);
  // Estados para modal e pedidos do dia
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [groupedOrders, setGroupedOrders] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);

  // Processa os dados para o gráfico
  const transformedData = useMemo(() => {
    const groupedData = groupByDay(orders);
    return calculateKPIs(groupedData);
  }, [orders]);

  // Aplica filtro de período com base no tempo selecionado
  const filteredData = useMemo(() => {
    if (timeFilter === 'all') return transformedData;
    const days = timeFilter === '7days' ? 7 : 30;
    const cutoffDate = subDays(new Date(), days);
    return transformedData.filter(item => isAfter(new Date(item.date), cutoffDate));
  }, [timeFilter, transformedData]);

  // Calcula KPIs resumo para exibição
  const summaryKPIs = useMemo(() => {
    if (filteredData.length === 0) return {};
    const totalSales = filteredData.reduce((sum, item) => sum + item.total, 0);
    const totalOrders = filteredData.reduce((sum, item) => sum + item.orders, 0);
    const avgDailySales = totalSales / filteredData.length;
    const maxSale = Math.max(...filteredData.map(item => item.total));
    const minSale = Math.min(...filteredData.map(item => item.total));
    return { totalSales, totalOrders, avgDailySales, maxSale, minSale };
  }, [filteredData]);

  // Função para formatar datas para o eixo X
  const formatDate = (value) => {
    try {
      return format(parseISO(value), 'dd/MM');
    } catch (error) {
      return value;
    }
  };

  // Função de callback ao clicar em um dot do gráfico
  const handleDotClick = useCallback((payload) => {
    const day = payload.date;
    setSelectedDate(day);
    // Filtra os pedidos do dia clicado
    const ordersForDay = orders.filter(order => {
      const orderDate = format(parseISO(order.purchased_at), 'yyyy-MM-dd');
      return orderDate === day;
    });
    // Agrupa os pedidos por SKU
    const grouped = groupOrdersBySKU(ordersForDay);
    setGroupedOrders(grouped);
    setIsModalOpen(true);
  }, [orders]);

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-lg text-white">
      <h2 className="text-2xl font-bold text-center mb-4 text-purple-400">🚀 Vendas por Dia</h2>

      {/* Botões de filtro por período */}
      <div className="flex justify-center mb-4 space-x-4">
        <button
          onClick={() => setTimeFilter('7days')}
          className="px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded"
        >
          Última Semana
        </button>
        <button
          onClick={() => setTimeFilter('30days')}
          className="px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded"
        >
          Último Mês
        </button>
        <button
          onClick={() => setTimeFilter('all')}
          className="px-4 py-2 bg-blue-500 hover:bg-blue-600 rounded"
        >
          Todos
        </button>
      </div>

      {/* Toggles para exibir KPIs adicionais */}
      <div className="flex justify-center mb-6 space-x-4">
        <label className="flex items-center space-x-1">
          <input type="checkbox" checked={showMovingAverage} onChange={() => setShowMovingAverage(!showMovingAverage)} />
          <span>Média Móvel (7 dias)</span>
        </label>
        <label className="flex items-center space-x-1">
          <input type="checkbox" checked={showCumulative} onChange={() => setShowCumulative(!showCumulative)} />
          <span>Total Acumulado</span>
        </label>
        <label className="flex items-center space-x-1">
          <input type="checkbox" checked={showArea} onChange={() => setShowArea(!showArea)} />
          <span>Área Preenchida</span>
        </label>
      </div>

      {/* Exibição do resumo dos KPIs */}
      <div className="text-center mb-6">
        <p><strong>Total de Vendas:</strong> R$ {summaryKPIs.totalSales ? summaryKPIs.totalSales.toFixed(2) : '0.00'}</p>
        <p><strong>Total de Pedidos:</strong> {summaryKPIs.totalOrders || 0}</p>
        <p><strong>Média de Vendas Diária:</strong> R$ {summaryKPIs.avgDailySales ? summaryKPIs.avgDailySales.toFixed(2) : '0.00'}</p>
        <p><strong>Maior Venda em um Dia:</strong> R$ {summaryKPIs.maxSale ? summaryKPIs.maxSale.toFixed(2) : '0.00'}</p>
        <p><strong>Menor Venda em um Dia:</strong> R$ {summaryKPIs.minSale ? summaryKPIs.minSale.toFixed(2) : '0.00'}</p>
      </div>

      {/* Gráfico de Vendas */}
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={filteredData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
          <defs>
            <linearGradient id="cyberpunkGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="5%" stopColor="#ff00ff" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#00ffff" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#333" strokeDasharray="5 5" />
          <XAxis dataKey="date" tick={{ fill: '#ddd' }} tickFormatter={formatDate} />
          <YAxis tick={{ fill: '#ddd' }} />
          <Tooltip content={<CustomTooltip />} />
          <Legend wrapperStyle={{ color: '#ddd' }} />
          <Line
            type="monotone"
            dataKey="total"
            name="Total de Vendas"
            stroke="url(#cyberpunkGradient)"
            strokeWidth={3}
            activeDot={{ r: 8 }}
            dot={(dotProps) => <CustomDot {...dotProps} onDotClick={handleDotClick} />}
          />
          {showMovingAverage && (
            <Line
              type="monotone"
              dataKey="movingAverage"
              name="Média Móvel (7 dias)"
              stroke="#ffa500"
              strokeWidth={2}
              dot={false}
            />
          )}
          {showCumulative && (
            <Line
              type="monotone"
              dataKey="cumulativeTotal"
              name="Total Acumulado"
              stroke="#00ff00"
              strokeWidth={2}
              dot={false}
            />
          )}
          {showArea && (
            <Area
              type="monotone"
              dataKey="total"
              name="Área de Vendas"
              stroke="none"
              fill="url(#cyberpunkGradient)"
              fillOpacity={0.3}
            />
          )}
          <Brush dataKey="date" height={30} stroke="#ff00ff" tickFormatter={formatDate} />
        </LineChart>
      </ResponsiveContainer>

      {/* Modal para exibir os pedidos agrupados por SKU */}
      <OrderModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        groupedOrders={groupOrdersBySKU(orders.filter(order => format(parseISO(order.purchased_at), 'yyyy-MM-dd') === selectedDate))}
        selectedDate={selectedDate || ''}
      />
    </div>
  );
};

SalesChart.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default SalesChart;
