import React, { useContext, useMemo, useState } from 'react';
import { DadosContext } from '../../common/DadosContext';
import Filters from './Filters';
import OrderTable from './OrderTable';
import StatusValues from './StatusValues';
import Pagination from './Pagination';
import { CurvaABC, PaymentMethods } from './Abc.js';
import SalesChart from './SaleChart.js';
import TopSellingProducts from './TopSellingProducts.js';
import FallingProducts from './FallingProducts.js';

const OrdersDashboard = () => {
  const { tokens } = useContext(DadosContext);

  // Filtros iniciais
  const [filters, setFilters] = useState({
    status: 'All',
    purchased_at__ge: '',
    purchased_at__le: '',
    code: '',
    _limit: 50,
    _sort: 'purchased_at:desc',
  });

  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [stats, setStats] = useState({
    new: 0,
    approved: 0,
    cancelled: 0,
    finished: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;

  // Localiza o token do "magalu"
  const token = useMemo(() => {
    return tokens.find((t) => t.id === 'magalu')?.token;
  }, [tokens]);

  // Converte a data para UTC antes de enviar à API no formato correto


  // Atualiza os filtros conforme o usuário altera os inputs
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    console.log(`🛠 Alterando filtro: ${name} => ${value}`); // 🔴 Debug para garantir que os valores mudam

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };


  // Função para montar parâmetros (GARANTINDO QUE A DATA SEJA INCLUÍDA)
  // ✅ Converte a data para UTC antes de enviar à API
  const adjustDateToUTC = (date, isStartOfDay) => {
    if (!date) return null; // 🔴 Retorna null se não houver data
    const d = new Date(date);
    if (isStartOfDay) {
      d.setUTCHours(0, 0, 0, 0);
    } else {
      d.setUTCHours(23, 59, 59, 999);
    }
    return d.toISOString();
  };

  // ✅ Função para construir parâmetros da API, garantindo que a data seja incluída corretamente
  const buildParams = (offset) => {
    const params = {
      _limit: filters._limit,
      _offset: offset,
      _sort: filters._sort,
    };

    if (filters.status && filters.status !== 'All') {
      params.status = filters.status;
    }

    if (filters.purchased_at__ge) {
      const startDate = adjustDateToUTC(filters.purchased_at__ge, true);
      if (startDate) {
        params.purchased_at__ge = startDate; // ✅ Garante que a data seja enviada corretamente
      }
    }

    if (filters.purchased_at__le) {
      const endDate = adjustDateToUTC(filters.purchased_at__le, false);
      if (endDate) {
        params.purchased_at__le = endDate; // ✅ Garante que a data seja enviada corretamente
      }
    }

    if (filters.code) {
      params.code = filters.code;
    }

    console.log("🔍 Parâmetros enviados para a API:", params); // ✅ Log para depuração
    return params;
  };

  // ✅ Busca pedidos paginados com os filtros de data corrigidos
  const fetchAllOrders = async () => {
    setLoading(true);
    setError('');
    setOrders([]);
    setCurrentPage(1);
  
    try {
      let offset = 0;
      let continueFetching = true;
  
      const startDateFilter = filters.purchased_at__ge ? new Date(adjustDateToUTC(filters.purchased_at__ge, true)) : null;
      const endDateFilter = filters.purchased_at__le ? new Date(adjustDateToUTC(filters.purchased_at__le, false)) : null;
  
      while (continueFetching) {
        const params = buildParams(offset);
        console.log("🔍 Buscando com parâmetros:", params);
  
        const response = await fetch('https://nerp.com.br/magalu/orders', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token, params }),
        });
  
        if (!response.ok) {
          throw new Error('Falha ao buscar pedidos.');
        }
  
        const data = await response.json();
        const currentResults = data.results || [];
  
        if (currentResults.length === 0) {
          console.log("🚫 Nenhum pedido retornado. Parando busca.");
          break;
        }
  
        setOrders(prevOrders => [...prevOrders, ...currentResults]); // 🚀 Atualizando o estado incrementando os novos pedidos
  
        for (let order of currentResults) {
          const orderDate = new Date(order.purchased_at);
  
          if (startDateFilter && orderDate < startDateFilter) {
            console.log(`🚨 Parando busca: Pedido ${order.id} está antes da data inicial`);
            continueFetching = false;
            break;
          }
  
          if (endDateFilter && orderDate > endDateFilter) {
            console.log(`🚨 Parando busca: Pedido ${order.id} está depois da data final`);
            continueFetching = false;
            break;
          }
        }
  
        if (!continueFetching) {
          break;
        }
  
        offset += filters._limit;
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  



  // Paginação local no front (caso queira exibir 50 por página)
  const paginatedOrders = React.useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return orders.slice(startIndex, startIndex + itemsPerPage);
  }, [orders, currentPage]);

  const totalPages = Math.ceil(orders.length / itemsPerPage);

  return (
    <div className="p-8 bg-gray-900 text-white min-h-screen overflow-x-auto">
      <h1 className="text-3xl font-bold text-center mb-6 text-purple-400">📊 Orders Dashboard</h1>
      <Filters filters={filters} handleInputChange={handleFilterChange} />
      <button
        onClick={fetchAllOrders}
        className="block mx-auto mt-4 px-6 py-2 bg-purple-600 hover:bg-purple-700 text-white font-semibold rounded-lg shadow-md"
      >
        🔄 Buscar Pedidos
      </button>
      {loading && <p className="text-center mt-4">Carregando...</p>}
      {error && <p className="text-center text-red-500 mt-4">{error}</p>}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6">
        <div className="p-6 bg-gray-900 rounded-lg shadow-lg flex flex-col justify-center min-h-[300px]">
          <PaymentMethods orders={orders} />
        </div>

        <div className="p-6 bg-gray-900 rounded-lg shadow-lg flex flex-col justify-center min-h-[300px]">
          <SalesChart orders={orders} />
        </div>
      </div>

      <TopSellingProducts orders={orders}/>
      <FallingProducts orders={orders}/>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mt-6 overflow-x-auto">
        <StatusValues orders={orders} />
        <div className="overflow-x-auto">
          <CurvaABC orders={orders} />
        </div>
      </div>
      <div className="overflow-y-auto max-h-[500px]">
        <OrderTable orders={paginatedOrders} />
      </div>
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={setCurrentPage} />
    </div>
  );
};

export default OrdersDashboard;
