import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link } from "react-router-dom";
import Logo from './Logo';
import MobileMenu from './mobile-menu';
import { AuthGoogleContext } from '../common/LoginContext';

export default function Navbar() {
  const { signed } = useContext(AuthGoogleContext);
  const [top, setTop] = useState(true);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [showNotasMenu, setShowNotasMenu] = useState(false);
  const [showLogisticaMenu, setShowLogisticaMenu] = useState(false);
  const [showMercadoLivreMenu, setShowMercadoLivreMenu] = useState(false);
  const [showMagaluMenu, setShowMagaluMenu] = useState(false);
  const [showAmericanasMenu, setShowAmericanasMenu] = useState(false);
  
  const subMenuRef = useRef(null);
  const notasMenuRef = useRef(null);
  const logisticaMenuRef = useRef(null);
  const mercadoLivreMenuRef = useRef(null);
  const magaluMenuRef = useRef(null);
  const americanasMenuRef = useRef(null);

  const scrollHandler = () => {
    window.pageYOffset > 10 ? setTop(false) : setTop(true);
  };

  useEffect(() => {
    scrollHandler();
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

  const handleClickOutside = (event) => {
    if (subMenuRef.current && !subMenuRef.current.contains(event.target)) {
      setShowSubMenu(false);
    }
    if (notasMenuRef.current && !notasMenuRef.current.contains(event.target)) {
      setShowNotasMenu(false);
    }
    if (logisticaMenuRef.current && !logisticaMenuRef.current.contains(event.target)) {
      setShowLogisticaMenu(false);
    }
    if (mercadoLivreMenuRef.current && !mercadoLivreMenuRef.current.contains(event.target)) {
      setShowMercadoLivreMenu(false);
    }
    if (magaluMenuRef.current && !magaluMenuRef.current.contains(event.target)) {
      setShowMagaluMenu(false);
    }
    if (americanasMenuRef.current && !americanasMenuRef.current.contains(event.target)) {
      setShowAmericanasMenu(false);
    }
  };

  useEffect(() => {
    if (showSubMenu || showNotasMenu || showLogisticaMenu || showMercadoLivreMenu || showMagaluMenu || showAmericanasMenu) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showSubMenu, showNotasMenu, showLogisticaMenu, showMercadoLivreMenu, showMagaluMenu, showAmericanasMenu]);

  return (
    <header className={`fixed w-full z-30 transition duration-300 ease-in-out ${!top ? 'bg-gray-900 shadow-lg' : 'bg-gray-900'}`}>
      <div className="px-5 sm:px-6">
        <div className="flex items-center justify-between h-16 md:h-20">

          <div className="shrink-0 mr-4">
            <Logo />
          </div>

          <nav className="hidden md:flex md:grow">
            <ul className="flex grow justify-end flex-wrap items-center">
              {signed ? (
                <>
                  {/* <li
                    onClick={() => setShowNotasMenu(!showNotasMenu)}
                    className="relative"
                    ref={notasMenuRef}
                  >
                    <Link to="#" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Notas</Link>
                    {showNotasMenu && (
                      <ul className="absolute top-full left-0 bg-white shadow-lg mt-1 rounded-lg">
                        <li>
                          <Link to="/nota/entrada" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-t-lg">Entrada</Link>
                        </li>
                        <li>
                          <Link to="/nota/ranking" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-b-lg">Análise</Link>
                        </li>
                      </ul>
                    )}
                  </li> */}
                  <li
                    onClick={() => setShowSubMenu(!showSubMenu)}
                    className="relative"
                    ref={subMenuRef}
                  >
                    <Link to="/integracao" className="font-medium text-gray-200 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Integrações</Link>
                   
                  </li>
                  {/* <li
                    onClick={() => setShowLogisticaMenu(!showLogisticaMenu)}
                    className="relative"
                    ref={logisticaMenuRef}
                  >
                    <Link to="#" className="font-medium text-gray-600 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Logística</Link>
                    {showLogisticaMenu && (
                      <ul className="absolute top-full left-0 bg-white shadow-lg mt-1 rounded-lg">
                        <li>
                          <Link to="/simulador" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-t-lg">Simulador JadLog</Link>
                        </li>
                        <li>
                          <Link to="/coleta" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-b-lg">Coleta</Link>
                        </li>
                        <li>
                          <Link to="/jadlog" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-b-lg">Lista Coleta</Link>
                        </li>
                      </ul>
                    )}
                  </li> */}
                  <li
                    onClick={() => setShowMercadoLivreMenu(!showMercadoLivreMenu)}
                    className="relative"
                    ref={mercadoLivreMenuRef}
                  >
                    <Link to="#" className="font-medium text-gray-200 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Mercado Livre</Link>
                    {showMercadoLivreMenu && (
                      <ul className="absolute top-full left-0 bg-white shadow-lg mt-1 rounded-lg">
                        <li>
                          <Link to="/mercadolivre/concorrente" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-t-lg">Pedidos</Link>
                        </li>
                        <li>
                          <Link to="/mercadolivre/categorias" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Produtos</Link>
                        </li>
                        <li>
                          <Link to="/mercadolivre/mensagens" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-b-lg">Mensagens</Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li
                    onClick={() => setShowMagaluMenu(!showMagaluMenu)}
                    className="relative"
                    ref={magaluMenuRef}
                  >
                    <Link to="#" className="font-medium text-gray-200 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Magalu</Link>
                    {showMagaluMenu && (
                      <ul className="absolute top-full left-0 bg-white shadow-lg mt-1 rounded-lg">
                        <li>
                          <Link to="/magazineluiza/pedidos" className="block px-4 py-2 text-gray-700 hover:bg-gray-200">Pedidos</Link>
                        </li>
                        <li>
                          <Link to="/magazineluiza/produtos" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-t-lg">Produtos</Link>
                        </li>
                        <li>
                          <Link to="/magazineluiza/atendimento" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-b-lg">Mensagens</Link>
                        </li>
                        <li>
                          <Link to="/magazineluiza/perguntas" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-b-lg">Perguntas</Link>
                        </li>
                        <li>
                          <Link to="/magazineluiza/chat" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-b-lg">Chat</Link>
                        </li>
                      </ul>
                    )}
                  </li>
                  <li
                    onClick={() => setShowAmericanasMenu(!showAmericanasMenu)}
                    className="relative"
                    ref={americanasMenuRef}
                  >
                    <Link to="#" className="font-medium text-gray-200 hover:text-gray-900 px-5 py-3 flex items-center transition duration-150 ease-in-out">Americanas</Link>
                    {showAmericanasMenu && (
                      <ul className="absolute top-full left-0 bg-white shadow-lg mt-1 rounded-lg">
                        <li>
                          <Link to="/americanas/pedidos" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-t-lg">Pedidos</Link>
                        </li>
                        <li>
                          <Link to="/americanas/precos" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-b-lg">Preços</Link>
                        </li>
                        <li>
                          <Link to="/americanas/sac" className="block px-4 py-2 text-gray-700 hover:bg-gray-200 rounded-b-lg">Sac</Link>
                        </li>
                      </ul>
                    )}
                  </li>
                </>
              ) : (
                <li>
                  <Link to="/login" className="btn-sm text-gray-200 bg-gray-900 hover:bg-gray-800 ml-3 px-4 py-2 rounded-lg">
                    <span>Entrar</span>
                    <svg className="w-3 h-3 fill-current text-gray-400 shrink-0 ml-2 -mr-1" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.707 5.293L7 .586 5.586 2l3 3H0v2h8.586l-3 3L7 11.414l4.707-4.707a1 1 0 000-1.414z" fillRule="nonzero" />
                    </svg>
                  </Link>
                </li>
              )}
            </ul>
          </nav>

          <MobileMenu />

        </div>
      </div>
    </header>
  );
}
