import React, { useState } from "react";
import PropTypes from "prop-types";
import { FaCheckCircle, FaClock, FaTimesCircle, FaShoppingCart, FaBox } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";

/* Modal Genérico com transições e título */
const Modal = ({ isOpen, onClose, title, children }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
          aria-modal="true"
          role="dialog"
        >
          <motion.div
            initial={{ scale: 0.8, y: 50 }}
            animate={{ scale: 1, y: 0 }}
            exit={{ scale: 0.8, y: 50 }}
            className="bg-gray-900 p-6 rounded-lg shadow-lg w-11/12 md:w-2/3 lg:w-1/2 max-h-[80vh] overflow-y-auto relative"
          >
            <div className="flex justify-between items-center mb-4">
              {title && (
                <h2 className="text-2xl font-bold text-white">{title}</h2>
              )}
              <button
                onClick={onClose}
                className="text-gray-400 hover:text-white"
                aria-label="Fechar modal"
              >
                ✖
              </button>
            </div>
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};

/* Modal para exibir pedidos de um SKU com opção de exportar para CSV */
const SkuOrdersModal = ({ isOpen, onClose, sku, productName, orders }) => {
  const exportCSV = () => {
    if (!orders || orders.length === 0) return;
    const header = "Código,Quantidade";
    const rows = orders.map((order) => `${order.code},${order.quantity}`);
    const csvContent = [header, ...rows].join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `pedidos_${sku}.csv`);
    link.click();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Pedidos - ${productName} (SKU: ${sku})`}
    >
      <div className="p-4 bg-gray-800 rounded-lg">
        {orders && orders.length > 0 ? (
          <table className="w-full text-left border-collapse">
            <thead>
              <tr className="bg-gray-700">
                <th className="p-2">Código</th>
                <th className="p-2">Quantidade</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, idx) => (
                <tr
                  key={idx}
                  className="border-b border-gray-600 hover:bg-gray-700"
                >
                  <td className="p-2">{order.code}</td>
                  <td className="p-2">{order.quantity}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p className="text-white">Nenhum pedido encontrado para este produto.</p>
        )}
        <div className="mt-4 flex justify-end">
          <button
            onClick={exportCSV}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 mr-2"
          >
            Exportar CSV
          </button>
          <button
            onClick={onClose}
            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
          >
            Fechar
          </button>
        </div>
      </div>
    </Modal>
  );
};

SkuOrdersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sku: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
  orders: PropTypes.array.isRequired,
};

/* Configuração dos ícones e cores para cada status */
const statusConfig = {
  new: { label: "Novo", color: "bg-blue-500", icon: <FaClock className="text-white text-2xl" title="Novo" /> },
  approved: { label: "Aprovado", color: "bg-green-500", icon: <FaCheckCircle className="text-white text-2xl" title="Aprovado" /> },
  finished: { label: "Finalizado", color: "bg-gray-500", icon: <FaBox className="text-white text-2xl" title="Finalizado" /> },
  cancelled: { label: "Cancelado", color: "bg-red-500", icon: <FaTimesCircle className="text-white text-2xl" title="Cancelado" /> },
  noStatus: { label: "Sem Status", color: "bg-yellow-500", icon: <FaShoppingCart className="text-white text-2xl" title="Sem Status" /> }
};

/* Componente principal que exibe o resumo dos pedidos */
const StatusValues = ({ orders }) => {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedSkuData, setSelectedSkuData] = useState(null);

  const totalValues = {};

  orders.forEach(order => {
    const status = order.status || "noStatus";
    const amount = order.amounts?.total / 100 || 0;

    if (!totalValues[status]) {
      totalValues[status] = { total: 0, count: 0, products: {} };
    }

    totalValues[status].total += amount;
    totalValues[status].count += 1;

    order.deliveries?.forEach(delivery => {
      delivery.items?.forEach(item => {
        const sku = item.info.sku;
        if (!totalValues[status].products[sku]) {
          totalValues[status].products[sku] = { name: item.info.name, quantity: 0, orders: [] };
        }
        totalValues[status].products[sku].quantity += item.quantity;
        totalValues[status].products[sku].orders.push({
          code: delivery.code.replace(/^LU-/, "").replace(/-1$/, ""),
          quantity: item.quantity
        });
      });
    });
  });

  const totalOrders = Object.values(totalValues).reduce(
    (sum, data) => sum + data.count,
    0
  );

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-lg text-white">
      <h2 className="text-2xl font-semibold text-center mb-4 text-purple-400">
        📊 Resumo de Pedidos
      </h2>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
        {Object.entries(totalValues).map(([status, data]) => {
          const percentage = ((data.count / totalOrders) * 100).toFixed(2);
          return (
            <motion.div
              key={status}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`p-4 rounded-lg shadow-lg cursor-pointer transition-transform ${statusConfig[status]?.color || "bg-gray-700"}`}
              onClick={() => {
                setSelectedStatus({ status, ...data });
                setModalOpen(true);
              }}
            >
              <div className="flex items-center space-x-3">
                {statusConfig[status]?.icon}
                <h3 className="text-lg font-semibold capitalize">
                  {statusConfig[status]?.label}
                </h3>
              </div>
              <p className="text-xl font-bold mt-2">R$ {data.total.toFixed(2)}</p>
              <p className="text-md">
                🛒 {data.count} vendas ({percentage}%)
              </p>
            </motion.div>
          );
        })}
      </div>

      <Modal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        title={selectedStatus ? statusConfig[selectedStatus.status]?.label : ""}
      >
        {selectedStatus && (
          <div className="p-6 bg-gray-800 rounded-lg">
            <p className="text-gray-300">
              Total de Pedidos: {selectedStatus.count}
            </p>
            <p className="text-gray-300">
              Valor Total: R$ {selectedStatus.total.toFixed(2)}
            </p>
            <div className="mt-4 max-h-[50vh] overflow-y-auto">
              <h3 className="text-lg font-semibold text-white mb-2">
                📦 Produtos Vendidos
              </h3>
              <table className="w-full text-left border-collapse">
                <thead>
                  <tr className="bg-gray-700">
                    <th className="p-2">SKU</th>
                    <th className="p-2">Produto</th>
                    <th className="p-2">Quantidade</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(selectedStatus.products).map(([sku, product]) => (
                    <tr
                      key={sku}
                      className="border-b border-gray-600 hover:bg-gray-700 cursor-pointer"
                      onClick={() =>
                        setSelectedSkuData({
                          sku,
                          productName: product.name,
                          orders: product.orders,
                        })
                      }
                    >
                      <td className="p-2">{sku}</td>
                      <td className="p-2">{product.name}</td>
                      <td className="p-2">{product.quantity}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Modal>

      {selectedSkuData && (
        <SkuOrdersModal
          isOpen={!!selectedSkuData}
          onClose={() => setSelectedSkuData(null)}
          sku={selectedSkuData.sku}
          productName={selectedSkuData.productName}
          orders={selectedSkuData.orders}
        />
      )}
    </div>
  );
};

StatusValues.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default StatusValues;
