import React, { useState, useEffect, useContext, useCallback } from "react";
import axios from "axios";
import { DadosContext } from "../../common/DadosContext";

const statusOptions = [
  { value: "OPENED", label: "Aguardando Resposta" },
  { value: "NEW", label: "Nova Conversa" },
  { value: "CLOSED", label: "Conversa Fechada" },
];

const formatDate = (isoDate) => {
  return new Date(isoDate).toLocaleDateString("pt-BR", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

// Custom hook para encapsular a lógica de requisição
const useConversations = (params) => {
  const { tokens } = useContext(DadosContext);
  const [conversations, setConversations] = useState([]);
  const [nextOffset, setNextOffset] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const fetchConversations = useCallback(async (overrideParams = {}) => {
    const { offset, limit, status, fromUser, startDate, endDate } = {
      ...params,
      ...overrideParams,
    };
    const token = tokens.find((t) => t.id === "magalu-chat")?.token;
    if (!token) {
      setError("Token não encontrado");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const response = await axios.post("https://nerp.com.br/magalu/chat", {
        accessToken: token,
        _limit: limit,
        _offset: offset,
        status: status || null,
        from_user_ref_key: fromUser || null,
        last_interaction_at_start: startDate
          ? new Date(startDate).toISOString()
          : null,
        last_interaction_at_end: endDate
          ? new Date(endDate).toISOString()
          : null,
      });

      setConversations(response.data.results || []);

      // Extrai o próximo offset da URL retornada
      const nextUrl = response.data.meta?.links?.next || null;
      if (nextUrl) {
        const urlParams = new URLSearchParams(nextUrl);
        const nextOffsetValue = urlParams.get("_offset");
        setNextOffset(nextOffsetValue ? parseInt(nextOffsetValue, 10) : null);
      } else {
        setNextOffset(null);
      }
    } catch (err) {
      console.error("Erro na busca:", err.response?.data || err.message);
      setError(err.response?.data?.error || "Erro ao buscar conversas");
    } finally {
      setLoading(false);
    }
  }, [tokens, params]);

  return { conversations, nextOffset, loading, error, fetchConversations };
};

// Componente para os filtros
const FilterForm = ({
  status,
  fromUser,
  startDate,
  endDate,
  setStatus,
  setFromUser,
  setStartDate,
  setEndDate,
  onFilter,
}) => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
      <select
        className="p-3 border rounded-lg"
        value={status}
        onChange={(e) => setStatus(e.target.value)}
      >
        <option value="">📌 Filtrar por Status</option>
        {statusOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      <input
        type="text"
        placeholder="Filtrar por Cliente (ID)"
        className="p-3 border rounded-lg"
        value={fromUser}
        onChange={(e) => setFromUser(e.target.value)}
      />
      <input
        type="datetime-local"
        className="p-3 border rounded-lg"
        value={startDate}
        onChange={(e) => setStartDate(e.target.value)}
      />
      <input
        type="datetime-local"
        className="p-3 border rounded-lg"
        value={endDate}
        onChange={(e) => setEndDate(e.target.value)}
      />
      <button
        onClick={onFilter}
        className="p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
      >
        🔍 Buscar Conversas
      </button>
    </div>
  );
};

// Componente para exibir a tabela de conversas
const ConversationTable = ({ conversations }) => {
  return (
    <div className="overflow-x-auto">
      <table className="w-full border-collapse bg-white shadow-md rounded-lg">
        <thead>
          <tr className="bg-blue-500 text-white">
            <th className="border p-3">ID</th>
            <th className="border p-3">Cliente</th>
            <th className="border p-3">Última Mensagem</th>
            <th className="border p-3">Status</th>
            <th className="border p-3">Última Interação</th>
          </tr>
        </thead>
        <tbody>
          {conversations.length > 0 ? (
            conversations.map((conv) => (
              <tr key={conv.id} className="hover:bg-gray-200 transition">
                <td className="border p-3 text-center">{conv.id}</td>
                <td className="border p-3 text-center">
                  {conv.from_user?.full_name || "N/A"}
                </td>
                <td className="border p-3 text-center">
                  {conv.last_message?.content || "Sem mensagem"}
                </td>
                <td className="border p-3 text-center">
                  {conv.status.replaceAll("_", " ")}
                </td>
                <td className="border p-3 text-center">
                  {formatDate(conv.last_interaction_at)}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5" className="border p-3 text-center">
                Nenhuma conversa encontrada
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

// Componente para os controles de paginação
const PaginationControls = ({ offset, limit, nextOffset, onNext, onPrev }) => {
  return (
    <div className="flex justify-between mt-6">
      <button
        className="p-3 bg-gray-500 text-white rounded-lg disabled:opacity-50"
        onClick={onPrev}
        disabled={offset === 0}
      >
        ⬅️ Anterior
      </button>
      <span className="p-3">Página {Math.ceil(offset / limit) + 1}</span>
      <button
        className="p-3 bg-gray-500 text-white rounded-lg"
        onClick={onNext}
        disabled={nextOffset === null}
      >
        Próximo ➡️
      </button>
    </div>
  );
};

const ConversationsViewer = () => {
  // Estados para filtros e paginação
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [status, setStatus] = useState("");
  const [fromUser, setFromUser] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const params = { offset, limit, status, fromUser, startDate, endDate };

  const { conversations, nextOffset, loading, error, fetchConversations } =
    useConversations(params);

  // Chama a API quando os parâmetros de paginação mudam
  useEffect(() => {
    fetchConversations();
  }, [offset, limit, fetchConversations]);

  const handleFilterChange = () => {
    // Reinicia a paginação e busca as conversas com os filtros aplicados
    setOffset(0);
    fetchConversations({ offset: 0 });
  };

  const handleNextPage = () => {
    if (nextOffset !== null) {
      setOffset(nextOffset);
    }
  };

  const handlePrevPage = () => {
    setOffset((prev) => Math.max(0, prev - limit));
  };

  return (
    <div className="p-6 max-w-6xl mx-auto bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold text-center mb-6">
        💬 Conversas dos Clientes
      </h1>

      <FilterForm
        status={status}
        fromUser={fromUser}
        startDate={startDate}
        endDate={endDate}
        setStatus={setStatus}
        setFromUser={setFromUser}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        onFilter={handleFilterChange}
      />

      {loading && <p className="text-center text-gray-600">Carregando...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}

      <ConversationTable conversations={conversations} />

      <PaginationControls
        offset={offset}
        limit={limit}
        nextOffset={nextOffset}
        onNext={handleNextPage}
        onPrev={handlePrevPage}
      />
    </div>
  );
};

export default ConversationsViewer;
