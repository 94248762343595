import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import jsFileDownload from "js-file-download";
import { DadosContext } from "../common/DadosContext";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// Componente Spinner para indicar carregamento
const Spinner = () => (
  <div className="flex items-center justify-center py-6">
    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500"></div>
  </div>
);

// Componente Modal com overlay, botão de fechar e suporte à tecla Esc
const Modal = ({ children, onClose }) => {
  React.useEffect(() => {
    const handleEsc = (e) => {
      if (e.key === "Escape") onClose();
    };
    window.addEventListener("keydown", handleEsc);
    return () => window.removeEventListener("keydown", handleEsc);
  }, [onClose]);

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      {/* Overlay */}
      <div
        className="absolute inset-0 bg-black opacity-50"
        onClick={onClose}
      ></div>
      {/* Conteúdo do Modal */}
      <div className="bg-white rounded-lg p-6 z-10 max-w-lg w-full shadow-lg relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-800 text-2xl"
          aria-label="Fechar"
        >
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

const Categorias = () => {
  const { tokens } = useContext(DadosContext);
  const [categories, setCategories] = useState([]);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState({
    minPrice: 0,
    maxPrice: "",
    condition: "all",
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const token = tokens.find((t) => t.id === "mercado_livre")?.token;

  // Busca todas as categorias
  const fetchCategories = async () => {
    if (!token) {
      toast.error("Digite um ID de vendedor!");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        "https://nerp.com.br/mercado-livre/categorias",
        { accessToken: token }
      );
      const sortedCategories = response.data.sort(
        (a, b) =>
          (b.total_items_in_this_category || 0) -
          (a.total_items_in_this_category || 0)
      );
      setCategories(sortedCategories);
      setBreadcrumb([]);
      setSelectedCategory(null);
      setItems([]);
      setCurrentPage(1);
    } catch (error) {
      toast.error("Erro ao buscar categorias: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Busca detalhes da categoria (e suas subcategorias)
  const fetchCategoryDetails = async (
    categoryId,
    categoryName,
    updateBreadcrumb = true
  ) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://nerp.com.br/mercado-livre/categoria/detalhes",
        {
          accessToken: token,
          categoryId,
        }
      );
      const sortedChildrenCategories = response.data.children_categories.sort(
        (a, b) => b.total_items_in_this_category - a.total_items_in_this_category
      );
      setSelectedCategory({
        ...response.data,
        children_categories: sortedChildrenCategories,
      });
      if (updateBreadcrumb) {
        setBreadcrumb((prev) => [...prev, { id: categoryId, name: categoryName }]);
      }
      // Carrega os itens da categoria na página 1
      fetchCategoryItems(categoryId, 1);
    } catch (error) {
      toast.error("Erro ao buscar detalhes da categoria: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Busca itens da categoria com suporte à paginação
  const fetchCategoryItems = async (categoryId, page = 1) => {
    try {
      setLoading(true);
      const response = await axios.post(
        "https://nerp.com.br/mercado-livre/categoria/itens",
        {
          accessToken: token,
          categoryId,
          page,
        }
      );
      setItems(response.data.results);
      setCurrentPage(page);
    } catch (error) {
      toast.error("Erro ao buscar itens da categoria: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Busca detalhes de um item específico
  const fetchItemDetails = async (itemId) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://nerp.com.br/mercado-livre/item/${itemId}`
      );
      setSelectedItem(response.data);
    } catch (error) {
      toast.error("Erro ao buscar detalhes do item: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Permite clicar em um item do breadcrumb para voltar diretamente àquele nível
  const handleBreadcrumbClick = (index) => {
    const newBreadcrumb = breadcrumb.slice(0, index + 1);
    setBreadcrumb(newBreadcrumb);
    const lastCategory = newBreadcrumb[newBreadcrumb.length - 1];
    fetchCategoryDetails(lastCategory.id, lastCategory.name, false);
  };

  // Botão "Voltar" – volta para a categoria anterior ou para a listagem geral
  const handleGoBack = () => {
    if (breadcrumb.length > 0) {
      const newBreadcrumb = [...breadcrumb];
      newBreadcrumb.pop();
      setBreadcrumb(newBreadcrumb);
      if (newBreadcrumb.length > 0) {
        const lastCategory = newBreadcrumb[newBreadcrumb.length - 1];
        fetchCategoryDetails(lastCategory.id, lastCategory.name, false);
      } else {
        fetchCategories();
      }
    } else {
      fetchCategories();
    }
  };

  // Aplica os filtros de preço e condição nos itens
  const applyFilters = (itemsList) => {
    return itemsList.filter(
      (item) =>
        item.price >= filters.minPrice &&
        (filters.maxPrice === "" || item.price <= Number(filters.maxPrice)) &&
        (filters.condition === "all" || item.condition === filters.condition)
    );
  };

  // Exporta os dados das categorias para CSV
  const exportData = () => {
    const csvContent = categories
      .map((cat) => `${cat.name},${cat.total_items_in_this_category}`)
      .join("\n");
    jsFileDownload(csvContent, "categorias.csv");
  };

  // Manipula as alterações nos inputs dos filtros
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (token) {
      fetchCategories();
    } else {
      toast.error("Digite um ID de vendedor!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="container mx-auto p-6">
      <ToastContainer position="top-right" autoClose={5000} />

      <h1 className="text-3xl font-bold mb-6 text-center">
        Categorias do Mercado Livre
      </h1>

      {/* Breadcrumbs com itens clicáveis */}
      {breadcrumb.length > 0 && (
        <div className="flex items-center mb-4 text-gray-600">
          <button
            onClick={handleGoBack}
            className="text-blue-500 hover:underline mr-2"
          >
            Voltar
          </button>
          {breadcrumb.map((crumb, index) => (
            <span
              key={crumb.id}
              className="cursor-pointer hover:underline"
              onClick={() => handleBreadcrumbClick(index)}
            >
              {index > 0 && " > "}
              <span>{crumb.name}</span>
            </span>
          ))}
        </div>
      )}

      {/* Área de filtros */}
      <div className="mb-6 flex flex-col md:flex-row gap-4">
        <div className="flex items-center">
          <label htmlFor="minPrice" className="mr-2">
            Preço Mínimo:
          </label>
          <input
            type="number"
            id="minPrice"
            name="minPrice"
            value={filters.minPrice}
            onChange={handleFilterChange}
            className="border p-1 rounded"
            placeholder="0"
          />
        </div>
        <div className="flex items-center">
          <label htmlFor="maxPrice" className="mr-2">
            Preço Máximo:
          </label>
          <input
            type="number"
            id="maxPrice"
            name="maxPrice"
            value={filters.maxPrice}
            onChange={handleFilterChange}
            className="border p-1 rounded"
            placeholder="Sem limite"
          />
        </div>
        <div className="flex items-center">
          <label htmlFor="condition" className="mr-2">
            Condição:
          </label>
          <select
            id="condition"
            name="condition"
            value={filters.condition}
            onChange={handleFilterChange}
            className="border p-1 rounded"
          >
            <option value="all">Todas</option>
            <option value="new">Nova</option>
            <option value="used">Usada</option>
          </select>
        </div>
      </div>

      {/* Exibição do conteúdo ou indicador de carregamento */}
      {loading ? (
        <Spinner />
      ) : (
        <>
          {/* Exibe as subcategorias se uma categoria estiver selecionada */}
          {selectedCategory ? (
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {selectedCategory.children_categories.length > 0 ? (
                selectedCategory.children_categories.map((child) => (
                  <div
                    key={child.id}
                    className="p-4 border rounded-lg shadow-md bg-white cursor-pointer hover:shadow-lg transition-shadow"
                    onClick={() =>
                      fetchCategoryDetails(child.id, child.name)
                    }
                  >
                    <h2 className="text-lg font-bold">{child.name}</h2>
                    <p className="text-gray-500 text-sm">
                      {child.total_items_in_this_category.toLocaleString()}{" "}
                      itens
                    </p>
                  </div>
                ))
              ) : (
                <p className="col-span-full text-center text-gray-500">
                  Sem subcategorias
                </p>
              )}
            </div>
          ) : (
            // Exibe a listagem geral de categorias
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {categories.map((category) => (
                <div
                  key={category.id}
                  className="p-4 border rounded-lg shadow-md bg-white cursor-pointer hover:shadow-lg transition-shadow"
                  onClick={() =>
                    fetchCategoryDetails(category.id, category.name)
                  }
                >
                  <h2 className="text-lg font-bold">{category.name}</h2>
                  <p className="text-gray-500 text-sm">
                    {category.total_items_in_this_category
                      ? `${category.total_items_in_this_category.toLocaleString()} itens`
                      : "Sem informações"}
                  </p>
                </div>
              ))}
            </div>
          )}

          {/* Itens da categoria com filtros e paginação */}
          {items.length > 0 && (
            <div className="mt-8">
              <h3 className="text-xl font-bold mb-4">Itens na Categoria</h3>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {applyFilters(items).map((item) => (
                  <div
                    key={item.id}
                    className="p-4 border rounded-lg shadow-md bg-white cursor-pointer hover:shadow-lg transition-shadow"
                    onClick={() => fetchItemDetails(item.id)}
                  >
                    <img
                      src={item.thumbnail}
                      alt={item.title}
                      className="w-full h-40 object-cover mb-2 rounded"
                    />
                    <h4 className="font-bold text-lg">{item.title}</h4>
                    <p className="text-sm text-gray-500">
                      {item.price.toLocaleString()} {item.currency_id}
                    </p>
                  </div>
                ))}
              </div>

              {/* Controles de paginação */}
              <div className="flex justify-center items-center mt-6 gap-4">
                <button
                  onClick={() => {
                    if (currentPage > 1) {
                      fetchCategoryItems(selectedCategory.id, currentPage - 1);
                    }
                  }}
                  disabled={currentPage === 1}
                  className="px-4 py-2 bg-blue-500 text-white rounded disabled:opacity-50"
                >
                  Anterior
                </button>
                <span>Página {currentPage}</span>
                <button
                  onClick={() => {
                    fetchCategoryItems(selectedCategory.id, currentPage + 1);
                  }}
                  className="px-4 py-2 bg-blue-500 text-white rounded"
                >
                  Próxima
                </button>
              </div>
            </div>
          )}

          {/* Gráfico das categorias */}
          <div className="mt-8">
            <h3 className="text-xl font-bold mb-4">Gráfico de Categorias</h3>
            <div className="w-full h-64">
              <ResponsiveContainer>
                <BarChart
                  data={categories.map((cat) => ({
                    name: cat.name,
                    items: cat.total_items_in_this_category,
                  }))}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" tick={{ fontSize: 12 }} />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="items" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </div>
            <button
              onClick={exportData}
              className="mt-4 p-2 bg-blue-500 text-white rounded"
            >
              Exportar Dados
            </button>
          </div>
        </>
      )}

      {/* Modal para exibir detalhes do item */}
      {selectedItem && (
        <Modal onClose={() => setSelectedItem(null)}>
          <h2 className="text-2xl font-bold mb-4">{selectedItem.title}</h2>
          <p className="mb-4">
            {selectedItem.description || "Descrição não disponível."}
          </p>
          <button
            onClick={() => setSelectedItem(null)}
            className="px-4 py-2 bg-blue-500 text-white rounded"
          >
            Fechar
          </button>
        </Modal>
      )}
    </div>
  );
};

export default Categorias;
