import React, { useContext, useState, useMemo } from 'react';
import { DadosContext } from '../common/DadosContext';
import axios from 'axios';
import ProdutosList from './Produtos';

const SKUsList = () => {
    const { tokens, createProdutoMagalu } = useContext(DadosContext);
    const [skus, setSkus] = useState([]); 
    const [searchTerm, setSearchTerm] = useState(''); 
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchAllSKUs = async () => {
        const token = tokens.find((t) => t.id === 'magalu')?.token;
        if (!token) {
            setError('Token não encontrado');
            return;
        }

        setLoading(true);
        setError(null);
        let offset = 0;
        const limit = 100;
        const allSKUs = new Map();

        try {
            while (true) {
                const response = await axios.post('https://nerp.com.br/magalu/skus', {
                    accessToken: token,
                    limit,
                    offset,
                });

                const newSKUs = response?.data?.skus || [];
                if (newSKUs.length === 0) break;

                newSKUs.forEach((sku) => {
                    if (!allSKUs.has(sku.sku)) {
                        allSKUs.set(sku.sku, sku);
                    }
                });

                offset += limit;
            }

            setSkus(Array.from(allSKUs.values()));
        } catch (err) {
            console.error('Erro ao buscar SKUs:', err.response?.data || err.message || err);
            setError(err.response?.data?.message || 'Erro ao buscar SKUs');
        } finally {
            setLoading(false);
        }
    };

    const saveAllToFirebase = async () => {
        if (skus.length === 0) return;
        setLoading(true);
        try {
            await Promise.all(skus.map(createProdutoMagalu)); 
            alert('Todos os SKUs foram salvos com sucesso no Firebase!');
        } catch (error) {
            console.error('Erro ao salvar os SKUs no Firebase:', error);
            alert('Erro ao salvar os SKUs no Firebase.');
        } finally {
            setLoading(false);
        }
    };

    // ✅ Usando useMemo para evitar re-renderizações desnecessárias
    const filteredSKUs = useMemo(() => {
        return skus.filter((sku) => {
            const title = sku?.title?.toLowerCase() || '';
            const code = sku?.sku?.toLowerCase() || '';
            return title.includes(searchTerm.toLowerCase()) || code.includes(searchTerm.toLowerCase());
        });
    }, [searchTerm, skus]);

    return (
        <div className="container mx-auto px-4 py-10">
            <h1 className="text-2xl font-bold mb-4">Lista de SKUs</h1>

            {/* Botões de Ação */}
            <div className="mb-4 flex space-x-2">
                <button
                    className="px-4 py-2 bg-green-500 text-white rounded"
                    onClick={fetchAllSKUs}
                    disabled={loading}
                >
                    {loading ? 'Carregando...' : 'Buscar SKUs'}
                </button>
                <button
                    className="px-4 py-2 bg-blue-500 text-white rounded"
                    onClick={saveAllToFirebase}
                    disabled={skus.length === 0 || loading}
                >
                    Salvar Todos no Firebase
                </button>
            </div>

            {/* Componente de Produtos */}
            <ProdutosList />

            {/* Campo de busca */}
            <div className="mb-4">
                <input
                    type="text"
                    placeholder="Busque por nome ou código..."
                    className="w-full p-2 border rounded"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                />
            </div>

            {/* Exibição de Status */}
            {loading ? (
                <p className="text-center text-gray-500">Carregando...</p>
            ) : error ? (
                <p className="text-center text-red-500">{error}</p>
            ) : (
                <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                    {filteredSKUs.map((sku) => (
                        <li key={sku.sku} className="border p-4 rounded shadow">
                            <h3 className="text-lg font-bold">{sku.title || 'Título não disponível'}</h3>
                            <p className="text-sm text-gray-600">Marca: {sku.brand || 'Marca não especificada'}</p>
                            <p className="text-sm text-gray-500">Código: {sku.sku || 'Código indisponível'}</p>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default SKUsList;
