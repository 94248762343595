import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

// Função para converter data UTC para formato brasileiro
const formatDateToBR = (utcDate) => {
    if (!utcDate) return "Data inválida";
    const date = new Date(utcDate);
    return date.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" });
};

const OrderTable = ({ orders }) => {
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");

    // Filtrando os pedidos com base na pesquisa, garantindo que todos os valores existam
    const filteredOrders = orders.filter((order) => {
        const searchTerm = searchQuery.toLowerCase();

        return (
            (order?.code?.toLowerCase() || "").includes(searchTerm) || // Filtra pelo Order ID
            (order?.status?.toLowerCase() || "").includes(searchTerm) || // Filtra pelo Status
            (order?.deliveries?.[0]?.items?.[0]?.info?.name?.toLowerCase() || "").includes(searchTerm) || // Filtra pelo Nome do Produto
            (order?.payments?.[0]?.method?.toLowerCase() || "").includes(searchTerm) // Filtra pelo Método de Pagamento
        );
    });

    return (
        <div className="bg-black shadow-lg rounded-lg p-6 mt-6 overflow-hidden">
            <h2 className="text-xl font-semibold text-gray-700 mb-4">📋 Orders Table</h2>

            {/* Barra de Pesquisa */}
            <input
                type="text"
                placeholder="🔍 Pesquise por ID, Status, Produto ou Pagamento..."
                className="w-full px-4 py-2 mb-4 border rounded-lg text-black"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
            />

            <div className="overflow-x-auto max-h-[500px] overflow-y-auto">
                <table className="w-full border-collapse text-sm text-left">
                    <thead>
                        <tr className="bg-gray-200 text-gray-700">
                            <th className="px-4 py-2">Order ID</th>
                            <th className="px-4 py-2">Status</th>
                            <th className="px-4 py-2">Purchased At</th>
                            <th className="px-4 py-2">Payment Method</th>
                            <th className="px-4 py-2">Total Amount</th>
                            <th className="px-4 py-2">Product Name</th>
                            <th className="px-4 py-2">Product Image</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredOrders.length > 0 ? (
                            filteredOrders.map((order, index) => (
                                <tr
                                    key={order.id}
                                    className={`border-b cursor-pointer ${
                                        index % 2 === 0 ? "bg-black" : "bg-gray-800"
                                    } hover:bg-gray-700 transition`}
                                    onClick={() => setSelectedOrder(order)}
                                >
                                    <td className="px-4 py-2 font-medium">{order.code}</td>
                                    <td className="px-4 py-2 text-blue-600 capitalize">{order.status}</td>
                                    <td className="px-4 py-2">{formatDateToBR(order.purchased_at)}</td>
                                    <td className="px-4 py-2 capitalize">
                                        {order.payments?.[0]?.method || "N/A"}
                                    </td>
                                    <td className="px-4 py-2 font-semibold text-green-600">
                                        R$ {(order.amounts?.total / 100 || 0).toFixed(2)}
                                    </td>
                                    <td className="px-4 py-2">
                                        {order.deliveries?.[0]?.items?.[0]?.info?.name || "N/A"}
                                    </td>
                                    <td className="px-4 py-2">
                                        {order.deliveries?.[0]?.items?.[0]?.info?.images?.[0]?.url ? (
                                            <img
                                                src={order.deliveries[0].items[0].info.images[0].url}
                                                alt={order.deliveries[0].items[0].info.name}
                                                className="w-16 h-16 object-cover rounded-md shadow"
                                            />
                                        ) : (
                                            <span className="text-gray-500">No Image</span>
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center py-4 text-gray-500">
                                    Nenhum pedido encontrado.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Modal para exibir detalhes do pedido */}
            {selectedOrder && (
                <Dialog open={Boolean(selectedOrder)} onClose={() => setSelectedOrder(null)} maxWidth="md" fullWidth>
                    <DialogTitle>Detalhes do Pedido</DialogTitle>
                    <DialogContent>
                        <div className="space-y-4">
                            <p><strong>Order ID:</strong> {selectedOrder.code}</p>
                            <p><strong>Status:</strong> {selectedOrder.status}</p>
                            <p><strong>Data de Compra:</strong> {formatDateToBR(selectedOrder.purchased_at)}</p>
                            <p><strong>Nome do Cliente:</strong> {selectedOrder.customer?.name || "N/A"}</p>
                            <p><strong>Email:</strong> {selectedOrder.customer?.email || "N/A"}</p>
                            <p><strong>Telefone:</strong> {selectedOrder.customer?.phones?.[0]?.area_code || "N/A"}{selectedOrder.customer?.phones?.[0]?.number || "N/A"}</p>
                            <p><strong>Endereço:</strong> {selectedOrder.deliveries?.[0]?.shipping?.recipient?.address?.street || "N/A"}, {selectedOrder.deliveries?.[0]?.shipping?.recipient?.address?.number || "N/A"}, {selectedOrder.deliveries?.[0]?.shipping?.recipient?.address?.city || "N/A"} - {selectedOrder.deliveries?.[0]?.shipping?.recipient?.address?.state || "N/A"}</p>
                            <p><strong>Produto:</strong> {selectedOrder.deliveries?.[0]?.items?.[0]?.info?.name || "N/A"}</p>
                            <p><strong>Valor Total:</strong> R$ {(selectedOrder.amounts?.total / 100).toFixed(2)}</p>
                            <p><strong>Forma de Pagamento:</strong> {selectedOrder.payments?.[0]?.description || "N/A"}</p>
                            <p><strong>Número de Parcelas:</strong> {selectedOrder.payments?.[0]?.installments || "N/A"}</p>
                            <p><strong>Frete:</strong> R$ {(selectedOrder.amounts?.freight?.total / 100 || 0).toFixed(2)}</p>

                            <div className="flex items-center space-x-4">
                                {selectedOrder.deliveries?.[0]?.items?.[0]?.info?.images?.[0]?.url && (
                                    <img
                                        src={selectedOrder.deliveries[0].items[0].info.images[0].url}
                                        alt={selectedOrder.deliveries[0].items[0].info.name}
                                        className="w-32 h-32 object-cover rounded-lg shadow-md"
                                    />
                                )}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setSelectedOrder(null)} color="primary">
                            Fechar
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default OrderTable;
