import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { DadosContext } from "../../common/DadosContext";

const statusOptions = [
    "waiting_seller", "waiting_customer", "waiting_marketplace", "waiting_return_info",
    "waiting_item_return", "waiting_rating", "waiting_banking_account", "waiting_customer_complement",
    "waiting_rating_complement", "waiting_customer_close_ticket", "refund_authorized", "validating_bank_account",
    "refund_denied", "waiting_seller_pickup", "seller_already_pickedup", "waiting_picked_up", "closed",
    "waiting_partner_receive_collected_product", "reversal_made", "waiting_pickup", "waiting_checklist",
    "waiting_pickup_schedule", "waiting_to_complete_the_checklist", "failed_to_identify_reverse",
    "validating_return_invoice", "valid_return_invoice", "waiting_refund_release",
    "waiting_confirmation_collection_schedule", "failed_collection_schedule"
];

const reasonOptions = [
    "shipping_delay", "price_discrepancy", "incorrect_product", "missing_damaged_parts",
    "duplicated_order", "payment_issue", "quality_concern", "change_of_mind", "unavailability",
    "customer_error", "unexpected_circumstances", "shipping_address_issues", "general",
    "payment_authorization_failure", "payment_discrepancy"
];

const formatDate = (isoDate) => {
    return new Date(isoDate).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
    });
};

const TicketViewer = () => {
    const { tokens } = useContext(DadosContext);
    const [tickets, setTickets] = useState([]);
    const [limit, setLimit] = useState(10);
    const [offset, setOffset] = useState(0);
    const [status, setStatus] = useState("");
    const [reason, setReason] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const fetchTickets = async () => {
        const token = tokens.find((t) => t.id === "magalu")?.token;
        if (!token) {
            setError("Token não encontrado");
            setLoading(false);
            return;
        }

        setLoading(true);
        setError("");

        try {
            const response = await axios.post("https://nerp.com.br/magalu/conversas", {
                accessToken: token,
                limit,
                offset,
                status,
                reason,
            });

            setTickets(response.data.results);
        } catch (err) {
            setError(err.response?.data?.error || "Erro ao buscar tickets");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTickets();
    }, [offset, limit]);

    return (
        <div className="p-6 max-w-6xl py-36 mx-auto bg-gray-100 rounded-lg shadow-md">
            <h1 className="text-2xl font-bold text-center mb-6">📌 Tickets da Magalu</h1>

            {/* Filtros */}
            <div className="grid grid-cols-3 gap-4 mb-6">
                <select
                    className="p-3 border rounded-lg"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                >
                    <option value="">🔍 Filtrar por Status</option>
                    {statusOptions.map((option) => (
                        <option key={option} value={option}>
                            {option.replaceAll("_", " ")}
                        </option>
                    ))}
                </select>

                <select
                    className="p-3 border rounded-lg"
                    value={reason}
                    onChange={(e) => setReason(e.target.value)}
                >
                    <option value="">📌 Filtrar por Motivo</option>
                    {reasonOptions.map((option) => (
                        <option key={option} value={option}>
                            {option.replaceAll("_", " ")}
                        </option>
                    ))}
                </select>

                <button
                    onClick={fetchTickets}
                    className="p-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition"
                >
                    🔍 Buscar Tickets
                </button>
            </div>

            {/* Loading e Erro */}
            {loading && <p className="text-center text-gray-600">Carregando...</p>}
            {error && <p className="text-center text-red-500">{error}</p>}

            {/* Tabela de Tickets */}
            <div className="overflow-x-auto">
                <table className="w-full border-collapse bg-white shadow-md rounded-lg">
                    <thead>
                        <tr className="bg-blue-500 text-white">
                            <th className="border p-3">ID</th>
                            <th className="border p-3">Código</th>
                            <th className="border p-3">Status</th>
                            <th className="border p-3">Motivo</th>
                            <th className="border p-3">Criado em</th>
                            <th className="border p-3">Atualizado em</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tickets.length > 0 ? (
                            tickets.map((ticket) => (
                                <tr key={ticket.id} className="hover:bg-gray-200 transition">
                                    <td className="border p-3 text-center">{ticket.id}</td>
                                    <td className="border p-3 text-center">{ticket.code}</td>
                                    <td className="border p-3 text-center">{(ticket.status || "N/A").replaceAll("_", " ")}</td>
                                    <td className="border p-3 text-center">{(ticket.reason || "N/A").replaceAll("_", " ")}</td>
                                    <td className="border p-3 text-center">{formatDate(ticket.created_at)}</td>
                                    <td className="border p-3 text-center">{formatDate(ticket.updated_at)}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="6" className="border p-3 text-center">
                                    Nenhum ticket encontrado
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

            {/* Paginação */}
            <div className="flex justify-between mt-6">
                <button
                    className="p-3 bg-gray-500 text-white rounded-lg disabled:opacity-50"
                    onClick={() => setOffset((prev) => Math.max(0, prev - limit))}
                    disabled={offset === 0}
                >
                    ⬅️ Anterior
                </button>
                <button
                    className="p-3 bg-gray-500 text-white rounded-lg"
                    onClick={() => setOffset((prev) => prev + limit)}
                >
                    Próximo ➡️
                </button>
            </div>
        </div>
    );
};

export default TicketViewer;
