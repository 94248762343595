import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { DadosContext } from '../../common/DadosContext';

const Conversations = () => {
  const { tokens } = useContext(DadosContext);
  const [conversations, setConversations] = useState([]);
  const [pagination, setPagination] = useState({ limit: 20, offset: 0, total: 0 });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [status, setStatus] = useState('WAITING_RESPONSE');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answerText, setAnswerText] = useState('');

  useEffect(() => {
    fetchConversations();
  }, []);

  const fetchConversations = async () => {
    setLoading(true);
    setError(null);
    const token = tokens.find((t) => t.id === 'magalu-chat')?.token;
    if (!token) {
      setError('Token não encontrado');
      setLoading(false);
      return;
    }
    try {
      const response = await axios.post('https://nerp.com.br/magalu/msg', {
        accessToken: token,
        status,
        startDate,
        endDate,
      });
      const { results, meta } = response.data;
      setConversations(results.sort((a, b) => new Date(b.question.when_at) - new Date(a.question.when_at)));
      setPagination({ limit: meta.page.limit, offset: meta.page.offset, total: meta.page.total || results.length });
    } catch (err) {
      setError('Erro ao carregar conversas.');
    } finally {
      setLoading(false);
    }
  };

  const handleOpenAnswer = (conv) => {
    setSelectedQuestion(conv);
    setAnswerText('');
  };

  const handleSendAnswer = async () => {
    const token = tokens.find((t) => t.id === 'magalu-chat')?.token;
    if (!token) {
      alert('Token não encontrado');
      return;
    }
    try {
      await axios.post(`https://nerp.com.br/magalu/questions/${selectedQuestion.id}/answer`, {
        accessToken: token,
        message: answerText,
        owner: { name: 'LinhaEvo', external_id: 'ev' },
      });
      setSelectedQuestion(null);
      fetchConversations();
    } catch (err) {
      alert('Erro ao enviar resposta.');
    }
  };

  return (
    <div className="py-24 px-6 bg-gray-100 text-gray-800 min-h-screen">
      <h2 className="text-3xl font-bold text-center mb-8">Conversas</h2>

      <div className="flex flex-wrap items-end justify-center gap-4 mb-8">
        <div className="flex flex-col">
          <label className="mb-1 font-semibold">Status:</label>
          <select className="p-2 border rounded" value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="">Todos</option>
            <option value="APPROVED">Aprovado</option>
            <option value="WAITING_RESPONSE">Pendente</option>
          </select>
        </div>
        <input type="date" className="p-2 border rounded" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        <input type="date" className="p-2 border rounded" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        <button onClick={fetchConversations} className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">Filtrar</button>
      </div>

      {loading && <p className="text-center text-lg">Carregando...</p>}
      {error && <p className="text-center text-red-600">{error}</p>}

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {conversations.map((conv) => (
          <div key={conv.id} className="bg-white shadow rounded-lg p-4 flex flex-col">
            <img src={conv.subject.extra.url_img} alt={conv.subject.extra.name} className="w-full h-48 object-cover" />
            <h3 className="text-xl font-bold text-blue-600 mt-4">{conv.subject.extra.name}</h3>
            <p className="text-sm text-gray-600 mb-2">{conv.subject.extra.description}</p>
            <p className="mb-2 font-semibold">Pergunta: {conv.question.message}</p>
            {conv.answer && <p className="mb-2 font-semibold">Resposta: {conv.answer.message}</p>}
            <p className="text-xs text-gray-500">Data: {new Date(conv.question.when_at).toLocaleString('pt-BR')}</p>
            <a href={conv.subject.extra.url} target="_blank" rel="noopener noreferrer" className="text-blue-600 font-semibold hover:underline">Ver Produto</a>
            <button onClick={() => handleOpenAnswer(conv)} className="mt-2 px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700">Responder</button>
          </div>
        ))}
      </div>

      {selectedQuestion && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md w-96">
            <h3 className="text-lg font-semibold mb-4">Responder: {selectedQuestion.subject.extra.name}</h3>
            <textarea value={answerText} onChange={(e) => setAnswerText(e.target.value)} className="w-full border p-2 rounded" placeholder="Digite sua resposta..." />
            <div className="flex justify-end mt-4">
              <button onClick={() => setSelectedQuestion(null)} className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400">Cancelar</button>
              <button onClick={handleSendAnswer} className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700">Enviar</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Conversations;
