import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { format, parseISO, isAfter, subDays } from 'date-fns';

const TopSellingProducts = ({ orders }) => {
  const [period, setPeriod] = useState('30days'); // '7days', '30days', 'all'
  const [sortBy, setSortBy] = useState('quantity'); // 'quantity' or 'revenue'

  // Filtra os pedidos conforme o período selecionado
  const filteredOrders = useMemo(() => {
    if (period === 'all') return orders;
    const days = period === '7days' ? 7 : 30;
    const cutoff = subDays(new Date(), days);
    return orders.filter(order => isAfter(parseISO(order.purchased_at), cutoff));
  }, [orders, period]);

  // Agrega os dados por produto (SKU)
  const productStats = useMemo(() => {
    const stats = {};
    filteredOrders.forEach(order => {
      const date = format(parseISO(order.purchased_at), 'yyyy-MM-dd');
      order.deliveries?.forEach(delivery => {
        delivery.items?.forEach(item => {
          const sku = item.info.sku;
          if (!stats[sku]) {
            stats[sku] = {
              sku,
              name: item.info.name,
              image: item.info.images?.[0]?.url || '',
              quantity: 0,
              revenue: 0,
              sales: {},
            };
          }
          stats[sku].quantity += item.quantity;
          stats[sku].revenue += (item.unit_price.value / 100) * item.quantity;
          stats[sku].sales[date] = (stats[sku].sales[date] || 0) + item.quantity;
        });
      });
    });
    return Object.values(stats);
  }, [filteredOrders]);

  const totalQuantity = useMemo(() => productStats.reduce((sum, p) => sum + p.quantity, 0), [productStats]);
  const totalRevenue = useMemo(() => productStats.reduce((sum, p) => sum + p.revenue, 0), [productStats]);

  const sortedProducts = useMemo(() => {
    return [...productStats].sort((a, b) => b[sortBy] - a[sortBy]).slice(0, 10);
  }, [productStats, sortBy]);

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-lg text-white">
      <h2 className="text-2xl font-bold mb-4 text-purple-400 text-center">🔥 Produtos Mais Vendidos</h2>

      {/* Filtros */}
      <div className="flex justify-center gap-4 mb-6">
        <select
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
          className="p-2 bg-gray-800 rounded"
        >
          <option value="7days">Últimos 7 dias</option>
          <option value="30days">Últimos 30 dias</option>
          <option value="all">Todos</option>
        </select>
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          className="p-2 bg-gray-800 rounded"
        >
          <option value="quantity">Ordenar por Quantidade</option>
          <option value="revenue">Ordenar por Faturamento</option>
        </select>
      </div>

      {/* Totais */}
      <div className="text-center mb-6">
        <p>Total de Unidades Vendidas: <strong>{totalQuantity}</strong></p>
        <p>Total de Faturamento: <strong>R$ {totalRevenue.toFixed(2)}</strong></p>
      </div>

      {/* Cards dos produtos */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4">
        {sortedProducts.map((product, idx) => {
          const salesData = Object.entries(product.sales).map(([date, quantity]) => ({ date, quantity })).sort((a, b) => new Date(a.date) - new Date(b.date));
          const percentage = sortBy === 'quantity'
            ? ((product.quantity / totalQuantity) * 100).toFixed(1)
            : ((product.revenue / totalRevenue) * 100).toFixed(1);

          return (
            <div key={idx} className="bg-gray-800 p-4 rounded-lg shadow-md flex space-x-4 items-center">
              <img src={product.image} alt={product.name} className="w-16 h-16 rounded object-cover" />
              <div className="flex-1">
                <h3 className="font-semibold text-lg text-purple-300">{product.name}</h3>
                <p>Qtd Vendida: {product.quantity}</p>
                <p>Faturamento: R$ {product.revenue.toFixed(2)}</p>
                <p>Participação: {percentage}%</p>
                <ResponsiveContainer width="100%" height={60}>
                  <LineChart data={salesData}>
                    <XAxis dataKey="date" hide />
                    <YAxis hide domain={[0, 'auto']} />
                    <Tooltip formatter={(v) => `${v} und`} />
                    <Line type="monotone" dataKey="quantity" stroke="#00ffff" strokeWidth={2} dot={false} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

TopSellingProducts.propTypes = {
  orders: PropTypes.array.isRequired,
};

export default TopSellingProducts;