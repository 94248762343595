import React from 'react';

const Filters = ({ filters, handleInputChange, fetchAllOrders }) => (
    <div className="bg-white p-6 rounded-lg shadow-lg mb-6">
        <h2 className="text-xl font-semibold text-gray-700 mb-4">Filtros</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Filtro de Status */}
            <div className="flex flex-col">
                <label htmlFor="status" className="text-sm font-medium text-gray-600 mb-1">Status</label>
                <select
                    id="status"
                    name="status"
                    value={filters.status}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-500"
                >
                    <option value="">Todos</option>
                    <option value="new">Novo</option>
                    <option value="approved">Aprovado</option>
                    <option value="cancelled">Cancelado</option>
                    <option value="finished">Finalizado</option>
                </select>
            </div>

            {/* Filtro de Data Inicial */}
            <div className="flex flex-col">
                <label htmlFor="purchased_at__ge" className="text-sm font-medium text-gray-600 mb-1">Data Inicial</label>
                <input
                    id="purchased_at__ge"
                    type="date"
                    name="purchased_at__ge"
                    value={filters.purchased_at__ge}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-500"
                />
            </div>

            {/* Filtro de Data Final */}
            <div className="flex flex-col">
                <label htmlFor="purchased_at__le" className="text-sm font-medium text-gray-600 mb-1">Data Final</label>
                <input
                    id="purchased_at__le"
                    type="date"
                    name="purchased_at__le"
                    value={filters.purchased_at__le}
                    onChange={handleInputChange}
                    className="p-2 border rounded-md bg-gray-100 focus:ring-2 focus:ring-blue-500"
                />
            </div>
        </div>

        {/* Botão de Ação */}
        <div className="mt-6 flex justify-center">
            <button
                onClick={fetchAllOrders}
                className="px-6 py-2 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700 transition duration-200"
            >
                Buscar Pedidos
            </button>
        </div>
    </div>
);

export default Filters;